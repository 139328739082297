<template>
  <div class="space-y-1">
    <el-form class="action-container space-x-1" @submit.native.prevent="fetch">
      <el-button type="info" @click="$router.push('/proxy/create')"
        >添加代理</el-button
      >
      <el-input
        placeholder="请搜索关键词"
        class="action-input"
        v-model="paginate.keywords"
      />
      <el-button type="primary" class="action-button" native-type="submit"
        >搜索</el-button
      >
      <el-button type="basic" @click="resetSearch">重置</el-button>
    </el-form>
    <el-alert
      >代理格式为：用户名:密码@服务器ip:代理端口
      如[username:password@127.0.0.1:4780]</el-alert
    >
    <el-table :data="proxies" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="url" label="代理URL"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="scope.row.status"
            @change="handleProxyStatusChange(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="usable" label="是否可用">
        <template slot-scope="scope">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            :value="scope.row.usable"
          />
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button
            @click="$router.push(`/proxy/edit/${scope.row.id}`)"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="handleRemove(scope.row)"
            >删除</el-button
          >
          <el-button type="text" size="small" @click="checkProxy(scope.row)"
            >检查是否可用</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginate.page"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="paginate.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="paginate.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
      },
      proxies: [],
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const res = await this.$http.request({
        url: 'proxies',
        methods: 'get',
        params: this.paginate,
      })
      this.proxies = res.data.data
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page) {
      this.paginate.page = page
      this.fetch()
    },
    handleRemove(row) {
      this.$confirm(`是否要删除该代理?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`proxies/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async checkProxy(row) {
      const result = await this.$http.post(`proxies/${row.id}/check`)
      if (result.data.usable) {
        this.$message.success('该代理可用')
      } else {
        this.$message.error('该代理不可用')
      }
      this.fetch()
    },
    async handleProxyStatusChange(row) {
      await this.$http.put(`proxies/${row.id}`, row)
      this.$message.success('更新成功')
      this.fetch()
    },
  },
}
</script>
